//jsxhook

import _isString from "lodash/isString"
import NextLink, { LinkProps as NextLinkProps } from "next/link"
import React from "react"
import { LinkProps as ThemeLinkProps } from "theme-ui"
import { UrlObject } from "url"

export { default as NextLink } from "next/link"

// Both next.js and theme-ui define an "as" property.
// Unset it and force the next.js property
export type Props = Omit<Omit<ThemeLinkProps & NextLinkProps, "as">, "href"> & {
  href: string | UrlObject
  as?: string | UrlObject
}

export interface LinkProps {
  href: string
  as?: string
}

export const Link = React.forwardRef<HTMLAnchorElement, Props>(
  (
    {
      as,
      href,
      passHref = true,
      replace,
      scroll,
      shallow,
      prefetch,
      children,
      ...props
    },
    ref
  ) => {
    return as && _isString(as) && as.startsWith("http") ? (
      <a href={as} {...props}>
        {children}
      </a>
    ) : (
      <NextLink
        href={href}
        passHref={passHref}
        replace={replace}
        scroll={scroll}
        shallow={shallow}
        prefetch={prefetch}
        {...props}
      >
        {children}
      </NextLink>
    )
  }
)

export default Link
