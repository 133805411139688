import {
  Cart,
  DiscountedPrice,
  LineItem,
  Order,
  TypedMoney
} from "@commercetools/platform-sdk"
import _sum from "lodash/sum"

export interface CentAmount {
  centAmount: number
}

const lineItems = (cart: Cart | Order | undefined): LineItem[] => {
  return cart?.lineItems || []
}

const totalPrice = (cart: Cart | Order | undefined): TypedMoney | undefined => {
  if (cart && cart.taxedPrice) {
    return cart.taxedPrice.totalGross
  } else if (cart) {
    return cart.totalPrice
  } else {
    return undefined
  }
}

const totalPriceCentAmount = (cart: Cart | Order | undefined): CentAmount => {
  const price = totalPrice(cart)
  if (price !== undefined) {
    return { centAmount: price.centAmount }
  } else {
    return { centAmount: 0 }
  }
}

const subTotal = (cart: Cart | Order | undefined): CentAmount => {
  return {
    centAmount: _sum(
      lineItems(cart).map(it => lineItemSubTotalCentAmount(it).centAmount)
    )
  }
}

const subTotalOriginalPrice = (cart: Cart | Order | undefined): CentAmount => {
  return {
    centAmount: _sum(
      lineItems(cart).map(it => lineItemOriginalTotalPrice(it).centAmount)
    )
  }
}

const hasPromoCode = (item: LineItem) => {
  return item.discountedPricePerQuantity.length > 0
}

const hasDiscount = (item: LineItem) => {
  return item.price.discounted !== undefined
}

const getPromoCodeDiscount = (item: LineItem): CentAmount => {
  const itemPrice = item.quantity * item.price.value.centAmount
  const discountedPrices = item.discountedPricePerQuantity.map(discount => {
    return discount.quantity * discount.discountedPrice.value.centAmount
  })

  return { centAmount: itemPrice - _sum(discountedPrices) }
}

const getPriceDiscount = (item: LineItem): CentAmount => {
  if (item.price.discounted) {
    const itemPrice = item.quantity * item.price.value.centAmount
    const discountedPrice =
      item.quantity * item.price.discounted.value.centAmount
    return { centAmount: itemPrice - discountedPrice }
  } else {
    return { centAmount: 0 }
  }
}

const getDiscount = (item: LineItem) => {
  if (hasPromoCode(item)) {
    return getPromoCodeDiscount(item)
  } else if (hasDiscount(item)) {
    return getPriceDiscount(item)
  } else {
    return { centAmount: 0 }
  }
}

const totalDiscount = (cart: Cart | Order | undefined): CentAmount => {
  const centAmount = _sum(
    lineItems(cart)
      .map(getDiscount)
      .map(discount => discount.centAmount)
  )

  return { centAmount }
}

const taxes = (cart: Cart | Order | undefined): TypedMoney | undefined => {
  if (cart && cart.taxedPrice?.taxPortions) {
    const centAmount = _sum(
      cart.taxedPrice.taxPortions.map(it => it.amount.centAmount)
    )

    if (cart.taxedPrice.taxPortions.length > 0) {
      return {
        ...cart.taxedPrice.taxPortions[0].amount,
        centAmount
      }
    } else {
      return undefined
    }
  } else {
    return undefined
  }
}

const taxesCentAmount = (cart: Cart | Order | undefined): CentAmount => {
  if (cart && cart.taxedPrice) {
    const centAmount = _sum(
      cart.taxedPrice.taxPortions.map(it => it.amount.centAmount)
    )
    return { centAmount }
  } else {
    return { centAmount: 0 }
  }
}

const shippingCostCentAmount = (cart: Cart | Order | undefined): CentAmount => {
  if (cart && cart.shippingInfo) {
    return { centAmount: cart.shippingInfo?.price.centAmount }
  } else {
    return { centAmount: 0 }
  }
}

const shippingCost = (
  cart: Cart | Order | undefined
): TypedMoney | undefined => {
  if (cart && cart.shippingInfo) {
    return cart.shippingInfo?.price
  } else {
    return undefined
  }
}

const totalItems = (cart: Cart | Order | undefined): number => {
  return _sum(cart?.lineItems.map(it => it.quantity) || [])
}

const lineItemSubTotalCentAmount = (
  item: LineItem & { discountedPrice?: DiscountedPrice }
): CentAmount => {
  {
    if (item.totalPrice) {
      return { centAmount: item.totalPrice.centAmount }
    } else {
      return { centAmount: item.price.value.centAmount * item.quantity }
    }
  }
}

const lineItemTotalPrice = (
  item: LineItem & { discountedPrice?: DiscountedPrice }
): TypedMoney => {
  if (item.totalPrice) {
    return item.totalPrice
  } else {
    return {
      ...item.price.value,
      centAmount: item.price.value.centAmount * item.quantity
    }
  }
}

const lineItemOriginalTotalPrice = (item: LineItem): TypedMoney => {
  return {
    ...item.price.value,
    centAmount: item.price.value.centAmount * item.quantity
  }
}

export const CartCalculator = {
  totalPriceCentAmount,
  totalPrice,
  subTotal,
  subTotalOriginalPrice,
  shippingCostCentAmount,
  shippingCost,
  taxesCentAmount,
  taxes,
  totalDiscount,
  totalItems,
  lineItemSubTotalCentAmount,
  lineItemTotalPrice,
  lineItemOriginalTotalPrice
}
