//jsxhook

import { Country } from "@sixty-six-north/i18n"
import { Option } from "funfix-core"
import React, { useContext } from "react"
import { NextLink } from "../components/Links"
import { useStoreContext } from "../i18n/StoreHooks"
import { PrismicLinkBuilder, PrismicLinkHook } from "./PrismicLinks"
import { PrismicLinkedDocument } from "./PrismicModels"

export const PrismicLinkedDocumentContext = React.createContext<
  PrismicLinkedDocument[]
>([])

export const linkForDocumentIdBuilder =
  (documents: PrismicLinkedDocument[], country: Country) =>
  (id: string | undefined): NextLink | undefined => {
    const linkBuilder = new PrismicLinkBuilder(country)

    const doc =
      documents.find(it => it.id === id) ||
      documents.find(it => it.alternate_languages.some(al => al.id === id))

    return Option.of(doc)
      .map(it => linkBuilder.linkForDocument(it))
      .map(it => ({ href: { pathname: it }, as: it, passHref: true }))
      .getOrElse(undefined)
  }

export const usePrismicLinkedDocuments = (): PrismicLinkedDocument[] => {
  const linkedDocuments = useContext(PrismicLinkedDocumentContext)
  return linkedDocuments
}

export const usePrismicLinks = (): PrismicLinkHook => {
  const { country } = useStoreContext()
  const linkedDocuments = useContext(PrismicLinkedDocumentContext)
  const linkForDocumentId = linkForDocumentIdBuilder(linkedDocuments, country)
  return {
    linkForDocumentId
  }
}
