import { NextLink } from "../components/Links"
import { countryToPath } from "../i18n/Country"
import { getRegionForLocale } from "./PrismicLocale"
import {
  PrismicDocument,
  PrismicLinkData,
  PrismicLinkedDocument
} from "./PrismicModels"
import { Country } from "@sixty-six-north/i18n"

export class PrismicLinkBuilder {
  private readonly country?: Country

  constructor(country?: Country) {
    this.country = country
  }

  public linkForDocument(doc: PrismicLinkedDocument): string {
    return (
      this.linkWithNextRegion(doc) ||
      this.linkWithDocumentLanguage(doc) ||
      this.linkWithoutRegion(doc)
    ).replace("//", "/")
  }

  public linkWithDocumentLanguage(doc: PrismicLinkedDocument | undefined) {
    if (!doc?.lang) return undefined

    const country = getRegionForLocale(doc.lang)?.country

    if (!country) return undefined

    if (doc.data?.path) return `/${countryToPath(country)}/${doc.data.path}`

    if (doc.uid) return `/${countryToPath(country)}/${doc.uid}`

    return undefined
  }

  public linkWithNextRegion(doc: PrismicLinkedDocument | undefined) {
    if (this.country && doc?.data?.display_url) {
      return `/${countryToPath(this.country)}/${doc.data.display_url}`
    } else if (this.country && doc?.data?.path) {
      return `/${countryToPath(this.country)}/${doc.data.path}`
    } else if (this.country && doc?.uid) {
      return `/${countryToPath(this.country)}/${doc.uid}`
    } else {
      return undefined
    }
  }

  private linkWithoutRegion(doc: PrismicLinkedDocument | undefined) {
    if (doc?.data?.path) {
      return `/${doc.data.path}`
    } else if (doc?.uid) {
      return `/${doc.uid}`
    } else {
      return ""
    }
  }
}

export interface PrismicLinkHook {
  linkForDocumentId(id: string | undefined): NextLink | undefined
}

export const linkResolver =
  (hook: PrismicLinkHook) =>
  (doc: PrismicDocument): string =>
    hook.linkForDocumentId(doc.id)?.as || ""
