import { Ratios } from "@sixty-six-north/ui-system"
import {
  PrismicData,
  PrismicDocument,
  PrismicItem,
  PrismicPrimaryProps,
  PrismicImage,
  PrismicColumnItem,
  PrismicDocumentLink
} from "./PrismicModels"

export class PrismicDocumentAdapter {
  private readonly document: PrismicDocument | undefined

  constructor(document?: PrismicDocument) {
    this.document = document
  }

  public get uid(): string {
    return this.document?.uid || ""
  }

  public metaTitle(fallback: string = ""): string {
    return this.document?.data?.title?.[0]?.text || fallback
  }

  public metaDescription(fallback: string = ""): string {
    return this.document?.data?.meta_description?.[0]?.text || fallback
  }

  public noIndexEnabled(fallback: boolean = false): boolean {
    return this.document?.data?.noindex_enabled || fallback
  }

  public path(fallback: string = ""): string {
    return this.document?.data?.path || fallback
  }

  public metaImage(fallback: string = ""): string {
    return this.document?.data?.opengraph_image?.url || fallback
  }

  public get data() {
    return new PrismicDataAdapter(this.document?.data || {})
  }
}

export class PrismicDataAdapter {
  private props: PrismicData | undefined

  constructor(props?: PrismicData) {
    this.props = props
  }

  columnOneTitle(fallback: string = ""): string {
    return this.props?.column_1_title?.[0]?.text || fallback
  }
  columnTwoTitle(fallback: string = ""): string {
    return this.props?.column_2_title?.[0]?.text || fallback
  }
  columnThreeTitle(fallback: string = ""): string {
    return this.props?.column_3_title?.[0]?.text || fallback
  }

  columnOne(fallback: PrismicColumnItem[] = []): PrismicColumnItem[] {
    return this.props?.column_1 || fallback
  }

  columnTwo(fallback: PrismicColumnItem[] = []): PrismicColumnItem[] {
    return this.props?.column_2 || fallback
  }

  columnThree(fallback: PrismicColumnItem[] = []): PrismicColumnItem[] {
    return this.props?.column_3 || fallback
  }

  get image(): PrismicImage | undefined {
    return this.props?.image
  }

  internalAdvertTitle(fallback: string = ""): string {
    return this.props?.internal_advert_title?.[0]?.text || fallback
  }

  internalAdvertText(fallback: string = ""): string {
    return this.props?.internal_advert_text?.[0]?.text || fallback
  }

  get internalAdvertLink(): PrismicDocumentLink | undefined {
    return this.props?.link
  }

  internalAdvertLinkLabel(fallback: string = ""): string {
    return this.props?.link_label || fallback
  }
}

export class PrismicPrimaryAdapter {
  private props: PrismicPrimaryProps

  constructor(props: PrismicPrimaryProps) {
    this.props = props
  }

  title(fallback: string = ""): string {
    return this.props?.title?.[0]?.text || fallback
  }

  the_text(fallback: string = ""): string {
    return this.props?.the_text?.[0]?.text || fallback
  }

  banner_title(fallback: string = ""): string {
    return this.props?.banner_title?.[0]?.text || fallback
  }
  section_title(fallback: string = ""): string {
    return this.props?.section_title?.[0]?.text || fallback
  }

  navigation_title(fallback: string = ""): string {
    return this.props?.navigation_title?.[0]?.text || fallback
  }

  article_list_title(fallback: string = ""): string {
    return this.props?.article_list_title?.[0]?.text || fallback
  }
  table_title(fallback: string = ""): string {
    return this.props?.table_title?.[0]?.text || fallback
  }
  statistic_title(fallback: string = ""): string {
    return this.props?.statistic_title?.[0]?.text || fallback
  }

  productRecommendationTitle(fallback: string = ""): string {
    return this.props?.product_recommendation_title?.[0]?.text || fallback
  }

  productRecommendationSubTitle(fallback: string = ""): string {
    return this.props?.product_recommendation_subtitle?.[0]?.text || fallback
  }

  productRecommendationAttributeKey(fallback: string = ""): string {
    return this.props?.product_recommendation_attribute_key || fallback
  }

  productRecommendationAttributeValue(fallback: string = ""): string {
    return this.props?.product_recommendation_attribute_value || fallback
  }

  count_label(fallback: string = ""): string {
    return this.props?.count_label?.[0]?.text || fallback
  }

  subtitle(fallback: string = ""): string {
    return this.props?.subtitle?.[0]?.text || fallback
  }

  statement(fallback: string = ""): string {
    return this.props?.statement?.[0]?.text || fallback
  }

  sentence(fallback: string = ""): string {
    return this.props?.sentence?.[0]?.text || fallback
  }

  label(fallback: string = ""): string {
    return this.props?.label?.[0]?.text || fallback
  }
  caption(fallback: string = ""): string {
    return this.props?.caption?.[0]?.text || fallback
  }

  embedHTML(fallback: string = ""): string {
    return this.props?.embed?.html || fallback
  }
  embedWidth(fallback: number = 0): number {
    return this.props?.embed?.width || fallback
  }
  embedHeight(fallback: number = 0): number {
    return this.props?.embed?.height || fallback
  }

  ratio(fallback: Ratios): Ratios {
    return this.props?.ratio || fallback
  }
  cardRatio(fallback: Ratios): Ratios {
    return this.props?.card_ratio || fallback
  }

  videoURL(fallback: string = ""): string {
    return this.props?.video?.url || fallback
  }

  hlsVideoURL(fallback: string = ""): string {
    return this.props?.imgix_video?.playback_url || fallback
  }

  gpxFile(fallback: string = ""): string {
    return this.props?.gpx_file?.url || fallback
  }

  address(fallback: string = ""): string {
    return this.props?.address?.[0]?.text || fallback
  }

  contact(fallback: string = ""): string {
    return this.props?.contact?.[0]?.text || fallback
  }

  directions(fallback: string = ""): string {
    return this.props?.directions?.[0]?.text || fallback
  }

  get competitionEndDate(): number {
    const endDate = this.props.competition_end_date
    if (endDate) {
      return Date.parse(endDate)
    } else {
      return 0
    }
  }
}
export class PrismicItemAdapter {
  private props: PrismicItem

  constructor(props: PrismicItem) {
    this.props = props
  }

  sectionTitle(fallback: string = ""): string {
    return this.props?.section_title?.[0]?.text || fallback
  }

  cardTitle(fallback: string = ""): string {
    return this.props?.card_title?.[0]?.text || fallback
  }

  image(): PrismicImage | undefined {
    return this.props?.image
  }

  creditLabel(fallback: string = ""): string {
    return this.props?.credit_label?.[0]?.text || fallback
  }

  creditValue(fallback: string = ""): string {
    return this.props?.credit_value?.[0]?.text || fallback
  }

  anchorId(fallback: string = ""): string {
    return this.props?.anchor_id || fallback
  }

  label(fallback: string = ""): string {
    if (!this.props.label) return fallback
    if (Array.isArray(this.props?.label))
      return this.props?.label?.[0]?.text || fallback
    else return this.props?.label
  }

  value(fallback: string = ""): string {
    return this.props?.value?.[0]?.text || fallback
  }

  stat(fallback: string = ""): string {
    return this.props?.stat?.[0]?.text || fallback
  }

  get question_title() {
    return this.props.question_title?.[0]?.text
  }

  get question_text() {
    return this.props.question_text?.[0]?.text
  }

  additional_label(fallback: string = ""): string {
    return this.props?.additional_label?.[0]?.text || fallback
  }

  linkId(fallback: string = ""): string {
    return this.props?.link?.id || fallback
  }

  linkLabel(fallback: string = ""): string {
    return this.props?.link_label?.[0]?.text || fallback
  }

  link1Label(fallback: string = ""): string {
    return this.props?.link_1_label?.[0]?.text || fallback
  }
  link2Label(fallback: string = ""): string {
    return this.props?.link_2_label?.[0]?.text || fallback
  }
  link3Label(fallback: string = ""): string {
    return this.props?.link_3_label?.[0]?.text || fallback
  }
  link4Label(fallback: string = ""): string {
    return this.props?.link_4_label?.[0]?.text || fallback
  }
  tags(fallback: string = ""): string {
    return this.props?.tags || fallback
  }

  day(fallback: string = ""): string {
    return this.props?.day?.[0]?.text || fallback
  }

  time(fallback: string = ""): string {
    return this.props?.time?.[0]?.text || fallback
  }
  year(fallback: string = ""): string {
    return this.props?.year?.[0]?.text || fallback
  }
}
