//jsxhook

import { ImgixAspectRatio, ImgixImage } from "@sixty-six-north/ui-system"
import React from "react"
import { PrismicImage } from "../PrismicModels"

// need to look at optimising the duplication of query params
// .split("?")[0]

export const PrismicImgixImage: React.FC<{
  image?: PrismicImage
  aspectRatio: ImgixAspectRatio
  sizes?: string
  mobileSizes?: string
  width?: number
  height?: number
  lazyload?: boolean
}> = ({
  image,
  aspectRatio,
  sizes,
  mobileSizes,
  width,
  height,
  lazyload = true
}) => {
  if (image && image.url) {
    return (
      <ImgixImage
        imageUrl={image.url}
        imageMobileUrl={image.mobile?.url || ""}
        imageAlt={image.alt || ""}
        aspectRatio={aspectRatio}
        sizes={sizes}
        mobileSizes={mobileSizes}
        width={width}
        height={height}
        lazyload={lazyload}
      />
    )
  } else {
    return null
  }
}
